import React, { useEffect,useLayoutEffect,useState, useMemo, Suspense, createContext } from "react";
import "./App.css";
import {
  ADMIN_APP,
  DASHBOARD_APP,
  REPORT_APP,
  LOGIN_SCREEN,
  PagesList} from "./AppConstants";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  withRouter,
  useLocation
} from "react-router-dom";
import LoginScreen from "./components/admin/screens/LoginScreen";
import API from "./api";
import SystemAlertScreen from "./components/admin/screens/SystemAlertScreen";
import CustomAlert from "./components/common/CustomAlert";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import DashboardIcon from "@material-ui/icons/Dashboard";
import NavigationComponent from "./components/common/NavigationComponent";
import ChangePasswordScreen from "./components/common/ChangePassword";
import SuccessScreen from "./components/common/successScreen";
import ForgotPassword from "./components/admin/screens/ForgotPassword";
import { getDatabase,ref,onValue,onChildAdded,off, update,query, orderByKey, onChildRemoved } from "firebase/database";
import {getAuth} from 'firebase/auth'
// import  firebase from "firebase/app";
import { initializeApp } from 'firebase/app';
import NotificationComponent from "components/common/NotificationManagement";
// import "firebase/auth";
import ErrorComponent from "./components/common/ErrorComponent";
import {isEmpty,reject,filter,dissoc,forEachObjIndexed, assoc,mapObjIndexed,groupBy,assocPath,sortBy,prop} from "ramda";
import {atom,useRecoilState,selectorFamily,useRecoilValue,selector,useSetRecoilState} from 'recoil';
import { SnackbarProvider } from 'notistack';
import moment from 'moment';
import TimelineComponent from 'components/common/TimelineComponent';
import {ReactComponent as ActiveConsultIcon} from 'assets/icons/ActiveConsult.svg';
import {ReactComponent as Admin} from 'assets/icons/adminUsers.svg';
import {ReactComponent as SystemAlert} from 'assets/icons/alert.svg';
import {ReactComponent as Customer} from 'assets/icons/customer.svg';
import {ReactComponent as Hospital} from 'assets/icons/hospital.svg';
import {ReactComponent as Patient} from 'assets/icons/patient.svg';
import {ReactComponent as Provider} from 'assets/icons/provider.svg';
import {ReactComponent as MedicalRecord} from 'assets/icons/records.svg';
import {ReactComponent as ClinicianSignOut} from 'assets/icons/Switch.svg';
import {ReactComponent as Permissions} from 'assets/icons/permissions.svg';
import {ReactComponent as PendingConsultIcon} from 'assets/icons/Pending.svg';
import {ReactComponent as WardsIcon} from 'assets/icons/Wards.svg';
import {ReactComponent as StaffingIcon} from 'assets/icons/Staffing.svg';
import {ReactComponent as DashboardPageIcon} from 'assets/icons/Dashboard.svg';
import ReportExportsApp from './ReportApp';
import TermsAndCondition from "./components/admin/screens/TermsAndCondition";
import ProviderUserPassword from "./components/admin/screens/ProviderUserPassword";
import {AES256_GCM_ENCRYPT} from "components/common/EncryptionAndDecryption_3";
import ProviderNotification from "./components/common/providerNotification";
import ToastAlertComponent from 'ToastAlert';
import { detect } from 'detect-browser';
const browser = detect();

const pageAccessedByReload = (
  /*(window.performance.navigation && window.performance.navigation.type === 1) ||*/
    window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)
      .includes('reload')
);

const {REACT_APP_apiKey,REACT_APP_authDomain,REACT_APP_databaseURL,REACT_APP_projectId,REACT_APP_storageBucket,REACT_APP_messagingSenderId,REACT_APP_appId,REACT_APP_measurementId} = process.env

const firebaseConfig = {
    apiKey: REACT_APP_apiKey,
    authDomain: REACT_APP_authDomain,
    databaseURL: REACT_APP_databaseURL,
    projectId: REACT_APP_projectId,
    storageBucket: REACT_APP_storageBucket,
    messagingSenderId: REACT_APP_messagingSenderId,
    appId: REACT_APP_appId,
    measurementId: REACT_APP_measurementId
}

initializeApp(firebaseConfig);


export const SpecialityTypeList = createContext([])

let permissionValue = sessionStorage.getItem('permission')
if(permissionValue){permissionValue=JSON.parse(permissionValue)}

// const usersUid = sessionStorage.getItem('usersuid')

// const firebaseUid = sessionStorage.getItem('firebaseID');
// const loginUsersToken = sessionStorage.getItem("logoutUserToken");
// const loginUserEmails = sessionStorage.getItem("loginUserEmail");
// const authTokens = sessionStorage.getItem("authToken");

export const permission = atom({
  key:'permission',
  default:[]
})

export const roles = atom({
  key:'roles',
  default:[]
})

export const adminUserDetails = atom({
  key:'adminUserDetails',
  default:{}
})

export const PermissionCollection = atom({
  key:"permissionCollection",
  default:[]
  // enterpriseAdmin:['providerManagement','patientManagement','eConsultRecord'],
  // supportadminAccess:['patientManagement','eConsultRecord']
})

export const DelayNotificationTime = atom({
  key:'delayNotiTime',
  default:0
})
export const NotificationLoading = atom({
  key:'notificationLoading',
  default:true
})

export const usersID = atom({
  key:'usersUID',
  default:null
})

export const usersLoginToken = atom({
  key:'usersLoginToken',
  default:null
})

export const loginUserEmailId = atom({
  key: 'loginUserEmailId',
  default:''
})

export const authenticationToken = atom({
  key: 'authenticationToken',
  default: null
})

export const activePages = atom({
  key:'',
  default:''
})
export const firebaseIdGlobal = atom({
  key:'firebaseId',
  default:null
})

export const userIDBoolean = selector({
  key:'userIDBoolean',
  get:({get})=>{
    const permissionArray = get(permission)
    if(permissionArray.includes('superAdmin')){
      return false
    }
    return permissionArray.includes('enterpriseAdmin') || permissionArray.includes('supportadminAccess')
  }
})

export const UILevelPermission = selectorFamily({
  key:'uilevelpermission',
  get:(UIComponent)=>({get})=>{
    const permissionList = get(PermissionCollection);
    return permissionList.findIndex(({name})=>name===UIComponent) !== -1
  }
})

/* import  firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics"
import {isEmpty,reject,findIndex,remove} from "ramda"
/*import "firebase/firestore"
import axios from "axios"
import "firebase/functions"; */

export const TokenContext = createContext()

// const {REACT_APP_apiKey,REACT_APP_authDomain,REACT_APP_databaseURL,REACT_APP_projectId,REACT_APP_storageBucket,REACT_APP_messagingSenderId,REACT_APP_appId,REACT_APP_measurementId} = process.env

// var firebaseConfig = {
//   apiKey: REACT_APP_apiKey,
//   authDomain: REACT_APP_authDomain,
//   databaseURL: REACT_APP_databaseURL,
//   projectId: REACT_APP_projectId,
//   storageBucket: REACT_APP_storageBucket,
//   messagingSenderId: REACT_APP_messagingSenderId,
//   appId: REACT_APP_appId,
//   measurementId: REACT_APP_measurementId
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyB0ciCKAxKu37vGUbl6gBNSb0fC5Ff44a0",
//   authDomain: "dev-omnicure.firebaseapp.com",
//   databaseURL: "https://dev-omnicure.firebaseio.com",
//   projectId: "dev-omnicure",
//   storageBucket: "dev-omnicure.appspot.com",
//   messagingSenderId: "462063129427",
//   appId: "1:462063129427:web:d1fb129ea379d30441c7a6",
//   measurementId: "G-7CMWQ1D0HX"
// };
  // Initialize Firebase
// export const Firebase_APP =firebase.initializeApp(firebaseConfig);

// firebase.analytics();

// export const Firebase = atom({
//   key:'Firebase',
//   default: firebase
// })

// export const FirebaseApp = atom({
//   key:'FirebaseApp',
//   default: Firebase_APP
// })

export const AlertListNotification = atom({
  key:'NotificationAlertList',
  default: {}
})

export const unReadNotificationList = atom({
  key:'unreadnotificationlist',
  default:{}
})

export const ShowNotification = atom({
  key:'ShowNotification',
  default:{}
})

export const unreadNotificationCount = selector({
  key:'unreadNotificationCount',
  get:({get})=>{
    return Object.values(get(unReadNotificationList)).length
  }
})

export const getStatusPageUrl = atom({
  key:'StatusPageURL',
  default:()=>{

  }
})

const AdminApp = React.lazy(() =>
  import(
    /* webpackChunkName: "AdminApp",webpackPreload: true */ "./AdminExports"
  )
);
const DashboardApp = React.lazy(() =>
  import(/* webpackChunkName: "DashboardApp"*/ "./DashBoardExports")
);

const ReportApp = React.lazy(() =>
  import(/* webpackChunkName: "ReportApp"*/ "./ReportExports")
);

const TriageApp = React.lazy(() =>
  import(/* webpackChunkName: "TriageApp"*/ "./TriageExports")
);
const ResponsiveDrawer = React.lazy(() =>
  import(/* webpackChunkName: "DrawerToolbar"*/ "./components/common/SideBar")
);

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#9B51E0",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f",
    },
    success: {
      // This is green.A700 as hex.
      main: "#cb114c",
    },
  },
});

export const PrivateRoute = ({
  children,
  isCurrentApp,
  userId,
  component,
  location,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      component={
        userId != null && isCurrentApp
          ? component
          : () => (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
      }
    />
  );
};

// const userIdCache = sessionStorage.getItem("userId");
// const appCache = sessionStorage.getItem("app");
// const userTypeCache = sessionStorage.getItem("userType");
// const userDetailsCache = sessionStorage.getItem("userDetails");
// const globalToken = sessionStorage.getItem("tokenId");
// const globalRefreshId = sessionStorage.getItem("refreshId");
// const globalPermission = sessionStorage.getItem("userPermission");
// //let userEmail = sessionStorage.getItem("userEmailId");
// const firebaseID = sessionStorage.getItem("firebaseID");
// const globalProviderUserId = sessionStorage.getItem("providerUserId");
// const globalProviderUserToken = sessionStorage.getItem("providerUserToken");
// const globalProviderUserEmail = sessionStorage.getItem("providerUserEmail");
// const globalProviderUserPassword = sessionStorage.getItem("providerUserPassword");
// const globalAcceptedProvider = sessionStorage.getItem("acceptedProvider");
// const globalSessionFirebaseToken = sessionStorage.getItem("sessionFirebaseToken");
// const globalPasswordExpired = sessionStorage.getItem("passwordExpired");
// const globalPasswordExpiredToken = sessionStorage.getItem("passwordExpiredToken");

// if(userEmail !== String(null)){
//   userEmail = JSON.parse(userEmail)
// }
const RoutingComponent = ({location,history,userId,
  setUserId,
  app,
  setApp,
  setUserType,
  userType,
  setUserDetails,
  setUsersUid,
  setFirebaseId,
  setUserPermission,
  setRefreshId,
  setTokenId,loginRedirect,setLogoutUserToken}) => {
    if(loginRedirect){
      return <div style={{ fontSize: "22px" }}>
      Please{" "}
      <a
        style={{
          textDecoration: "underline",
          color: "#9B51E0",
          cursor: "pointer",
        }}
        onClick={() => {
          sessionStorage.clear();
          window.location.reload();
          /*setUserId(null);
          setUserDetails(null);
          setApp(null);
          setUsersUid(null)
          setFirebaseId(null)
          setUserPermission(null)
          setRefreshId(null)
          setTokenId(null)
          setLogoutUserToken(null)
          history.replace("/login");*/
        }}
      >
        Login
      </a>{" "}
      to access the application
    </div>
    }
    return <div
    style={{
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <div className="loader"></div>
  </div>
    /*else if(app && PagesList[app].includes(location.pathname)){
      return <div
      style={{
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="loader"></div>
    </div>
    }
    else if(app && allRoutes.includes(location.pathname)){
      return <div style={{ fontSize: "22px" }}>
      Please{" "}
      <a
        style={{
          textDecoration: "underline",
          color: "#9B51E0",
          cursor: "pointer",
        }}
        onClick={() => {
          setUserId(null);
          setUserDetails(null);
          setApp(null);
          setUsersUid(null)
          setFirebaseId(null)
          setUserPermission(null)
          setRefreshId(null)
          setTokenId(null)
          setLogoutUserToken(null)
          history.push("/login");
        }}
      >
        Login
      </a>{" "}
      to access the application
    </div>
    }
    else if(userId){
      return <Redirect to="/homepage" />
    }
    else if(!userId){
      return <Redirect to="/login" />
    }
    return null*/
  }

  //devops check

const App = ({ location }) => {
  const [userId, setUserId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [page, setPage] = useState(null);
  const setFirebaseIdRecoil = useSetRecoilState(firebaseIdGlobal)
  const [firebaseId,setFirebaseId] = useState(null)
  const [app, setApp] = useState(null);
  const [appinternalState,setAppInternalState] = useState();
  const [adminAppAccess,setAdminAppAccess] = useState(false);
  const [reportAppAccess,setReportAppAccess] = useState(false);
  const [dashboardAppAccess,setDashboardAppAccess] = useState(false);
  const [userType, setUserType] = useState(null);
  const [initialLoad,setInitialLoad] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("error");
  const [showSystemAlertModal, setSystemAlertShowModal] = useState(false);
  const [remoteProviderTypeList, setRemoteProviderTypeList] = useState([]);
  const [specialityTypeList, setSpecialityTypeList] = useState([]);
  const [tokenId, setTokenId] = useState(null);
  const [refreshId, setRefreshId] = useState(null);
  const [userPermission, setUserPermission] = useState(null);
  const [notificationDelay,setNotificationDelay] = useRecoilState(DelayNotificationTime)
  //const [userSessionEmail, setUserSessionEmail] = useState(userEmail === String(null)? [] :userEmail);
  const history = useHistory();
  const setAlertsList = useSetRecoilState(AlertListNotification);
  const setUnReadAlertsList = useSetRecoilState(unReadNotificationList)
  const setShowNotification = useSetRecoilState(ShowNotification)
  const [loginRedirect,setLoginRedirect] = useState(false)
  const setRoles = useSetRecoilState(permission);
  const setAdminUserDetails = useSetRecoilState(adminUserDetails);
  const [permissionPagesList,setPermissionPagesArray] = useRecoilState(PermissionCollection)
  const hospitalManagementAccess = useRecoilValue(UILevelPermission('Hospital management'))
  const providerManagementAccess = useRecoilValue(UILevelPermission('Provider management'))
  const patientManagementAccess = useRecoilValue(UILevelPermission('Patient management'))
  const eConsultRecordAccess = useRecoilValue(UILevelPermission('View eConsult record'))
  const systemAlertAccess = useRecoilValue(UILevelPermission('System alerts'))
  const clinicianAccess = useRecoilValue(UILevelPermission('Clinician Sign-out'))
  const adminUserManagementAccess = useRecoilValue(UILevelPermission('Admin user management'))
  const permissionAccess = useRecoilValue(UILevelPermission('View user permissions'))
  const setNotificationTime =useSetRecoilState(NotificationLoading)
  const DashboardScreenPermission = useRecoilValue(UILevelPermission('View dashboard'))
  const ActiveConsultsScreenPermission = useRecoilValue(UILevelPermission('View active consults'))
  const PendingConsultsScreenPermission = useRecoilValue(UILevelPermission('View pending consults'))
  const WardsScreenPermission = useRecoilValue(UILevelPermission('View wards'))
  const StaffingScreenPermission = useRecoilValue(UILevelPermission('View staffing data'))
  const QcReportScreenAccess = useRecoilValue(UILevelPermission('View consult metrics'))
  const HospitalReportScreenAccess = useRecoilValue(UILevelPermission('View hospital metrics'))
  const CustomerScreenAccess = useRecoilValue(UILevelPermission('Customer Management'))
  const [usersUid,setUsersUid] = useRecoilState(usersID)
  const [loginUserEmail, setLoginUserEmail]= useRecoilState(loginUserEmailId)
  const [logoutUserToken, setLogoutUserToken] = useRecoilState(usersLoginToken);
  //const [authToken, setAuthToken] = useRecoilState(authenticationToken);
  const pagelocation = useLocation();
  const [providerUserId, setProviderUserId] = useState(null);
  const [providerUserToken, setProviderUserToken] = useState(null);
  const [providerUserEmail, setProviderUserEmail] = useState(null);
  const [providerUserPassword, setProviderUserPassword] = useState(null)
  const [providerUserLogin, setProviderUserLogin] = useState(false);
  const [acceptedProvider, setAcceptedProvider] = useState(false);
  const [sessionFirebaseToken, setSessionFirebaseToken] = useState(null);
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [passwordExpiredToken, setPasswordExpiredToken] = useState(null);
  const setStatusURL = useSetRecoilState(getStatusPageUrl)
  const retryApiPost = (url,data,header)=>{
    return API.post(url,{...data,id:usersUid},assocPath(['headers','uid'],firebaseId,header)).then((res)=>{
      if(res?.headers?.userid===String(usersUid)){
        return Promise.resolve(res)
      }else{
        throw new Error("Invalid Response")
      }}).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": refreshId
        })
        .then((res)=>{
          setTokenId(res.data.idToken)
          if(!res.data.idToken){
              sessionStorage.clear();
              window.location.reload();
              //history.push("/login")
            throw error
          }else{
            resolve(API.post(url,{...data,id:usersUid},{headers:{
              Authorization:res.data.idToken,
              uid:firebaseId
            }}).then((res)=>{
              if(res?.headers?.userid===String(usersUid)){
                return Promise.resolve(res)
              }else{
                throw new Error("Invalid Response")
              }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }})
  }
 const retryApiGet = (url,header)=>{
    return API.get(url,header).catch(function(error){if([401,500,502,504,703,704].includes(error?.response?.status)){
      return new Promise(resolve=>{
        API.post("/userEndpoints/v1/renewIdToken",{
          "token": refreshId
        })
        .then((res)=>{
          setTokenId(res.data.idToken)
          if(!res.data.idToken){
            sessionStorage.clear();
            window.location.reload();
              //history.push("/login")

            throw error
          }else{
            resolve(API.get(url,{...header,headers:{
              Authorization:res.data.idToken
            }}))
          }
        })
        .catch((error) =>{Promise.reject(error)})
      })
    }})
  }
  useEffect(()=>{
    if(tokenId){
    setStatusURL(()=>()=>retryApiGet("/adminUserEndpoints/v1/GetAllConfigurations",{
      headers: {
        'Authorization': tokenId
      }
    }).then(({data:{configurationList}})=>configurationList.find(element => element.name === "statusPage")?.value))
  }},[tokenId])
  const hitSubUserTypeAPI = () => {
    API.get(
      "/userEndpoints/v1/getRemoteProviderType",{
        headers: {
          'Authorization': tokenId,
      }
      }
    )
      .then((res) => {
        if (res.data.status) {
          setRemoteProviderTypeList(res.data.remoteProviderTypeList);
        }
      });
  };
  const hitSpecialityTypeAPI = () => {
    API.post(
      "/commonEndpoints/v1/getAllSpecialtyTypes",{
        id:usersUid
      },{
        headers: {
          'Authorization': tokenId,
      }
      }
    )
      .then((res) => {
        if (res.data.status) {
          setSpecialityTypeList(sortBy(prop('order'),res.data.specialtyTypes))
        }
      })
  };
  useEffect(()=>{
    const auth = getAuth();
    const currentPageLocation = sessionStorage.getItem('pagelocation');
    if(pageAccessedByReload && currentPageLocation && currentPageLocation !== '/login' && currentPageLocation !== '/' &&  currentPageLocation !== '/app/termsandconditions' && currentPageLocation !== '/app/providerpassword'){
      auth.authStateReady().then(async()=>{
        if(auth.currentUser){
          const firebaseUid = auth.currentUser.uid;
          const tokenId = await auth.currentUser.getIdToken();
          const [EncryptedToken,EncryptedRefreshToken] = await Promise.all([AES256_GCM_ENCRYPT(tokenId),AES256_GCM_ENCRYPT(auth.currentUser.refreshToken)])
          API.post('/commonEndpoints/v1/adminLoginRefresh',{firebaseUid},{
            headers:{
              Authorization:EncryptedToken,
              uid:firebaseUid
            }
          }).then((res)=>{
            setFirebaseIdRecoil(firebaseUid)
            setLogoutUserToken(res?.data?.adminLoginUser?.token)
            setLoginUserEmail(res?.data?.adminLoginUser?.email)
            setUserId(res?.data?.adminLoginUser?.email)
            setUserType(res.data.adminLoginUser.userType);
            res.data.adminLoginUser.roles && setUserPermission("active");
            setUserDetails({ fname:res?.data?.adminLoginUser?.fname, lname:res?.data?.adminLoginUser?.lname });
            //setAuthToken(EncryptedToken)
            setTokenId(EncryptedToken)
            setRefreshId(EncryptedRefreshToken)
            setUsersUid(res?.data?.adminLoginUser?.id)
            setFirebaseId(firebaseUid)
            setAdminUserDetails(res?.data?.adminLoginUser)
            return res?.data
          }).then((adminData)=>{
            if(window.location.pathname !== '/'){
              history.push("/homepage")
              window.history.replaceState(null,"","/")
            } else{
              let app;
              for (let appName in PagesList){
                if(PagesList[appName].includes(currentPageLocation)){
                  app = appName;
                  break;
                }
              }
              setApp(app)
              if(currentPageLocation !== null){
                if(app===ADMIN_APP &&currentPageLocation!=='/homepage'){
                API.post("/commonEndpoints/v1/userValidation",{
                  page:'Administration',
                  id:adminData?.adminLoginUser?.id
                }, {
                  headers: {
                    'Authorization': EncryptedToken,
                    uid:firebaseUid,
                  }
                }).then((res)=>{
                  const accessAdmin = res.data.status
                  if(accessAdmin === true){
                    setAppInternalState(ADMIN_APP)
                    setAdminAppAccess(true)
                    setNotificationDelay(res.data.adminNotificationTime)
                    const groupByRoles = groupBy(({rollName})=>rollName,res?.data?.permissionList)
                    setRoles(Object.keys(groupByRoles))
                    setPermissionPagesArray(res?.data?.permissionList)
                    // const permissionList = pluck('name',res?.data?.userRole)
                    // setPermissionArray(permissionList)
                    // const rolePagesArray = res?.data?.userRole?.map(({name,permissions})=>(permissions?{[name]:pluck('name',permissions)}:{}))
                    // const rolePagesObject = rolePagesArray?.reduce((acc,curVal)=>({...acc,...curVal}))
                    // setPermissionPagesArray(rolePagesObject)
        
                    // adminPages.some(({accessible,route})=>{
                    //   if(accessible){
                    //     history.replace({pathname:route})
                    //     return false
                    //   }return true
                    // })
        
                  }            else{
                    setLoginRedirect(true)
                  }
                }).catch(()=>{setLoginRedirect(true)})}
                else if(app===DASHBOARD_APP&&currentPageLocation!=='/homepage'){
                  API.post("/commonEndpoints/v1/userValidation",{
                    page:'Operational Dashboard',
                    id:adminData?.adminLoginUser?.id
                  },{
                    headers: {
                      'Authorization': EncryptedToken,
                      uid:firebaseUid,
                    }
                  }).then((res)=>{
                    const accessDash = res.data.status
                    if(accessDash === true){
                      setAppInternalState(DASHBOARD_APP)
                      setDashboardAppAccess(true)
                      const groupByRoles = groupBy(({rollName})=>rollName,res?.data?.permissionList)
                      setRoles(Object.keys(groupByRoles))
                      setPermissionPagesArray(res?.data?.permissionList)
                      // const permissionList = pluck('name',res?.data?.userRole)
                      // setPermissionArray(permissionList)
                      // const rolePagesArray = res?.data?.userRole?.map(({name,permissions})=>(permissions?{[name]:pluck('name',permissions)}:{}))
                      // const rolePagesObject = rolePagesArray?.reduce((acc,curVal)=>({...acc,...curVal}))
                      // setPermissionPagesArray(rolePagesObject)
                      // // adminPages.some(({accessible,route})=>{
                      //   if(accessible){
                      //     history.replace({pathname:route})
                      //     return false
                      //   }return true
                      // })
        
                    }
                    else{
                      setLoginRedirect(true)
                    }
                  }).catch(()=>setLoginRedirect(true))}
                else if(app===REPORT_APP&&currentPageLocation!=='/homepage'){
                    API.post("/commonEndpoints/v1/userValidation",{
                      page:'Reports',
                      id:adminData?.adminLoginUser?.id
                    },{
                      headers: {
                        'Authorization': EncryptedToken,
                        uid:firebaseUid,
                      }
                    }).then((res)=>{
                      const accessDash = res.data.status
                      if(accessDash === true){
                        setAppInternalState(REPORT_APP);
                        setReportAppAccess(true);
                        const groupByRoles = groupBy(({ rollName }) => rollName, res?.data?.permissionList)
                        setRoles(Object.keys(groupByRoles))
                        setPermissionPagesArray(res?.data?.permissionList)
                      }
                      else{
                        setLoginRedirect(true)
                      }
                    }).catch(()=>setLoginRedirect(true))}
                   
                  history.replace(currentPageLocation)
              }
            }
          })
        }else{
           history.replace('/login')
        }
      })
    }else{
      history.replace('/login')
    }
  },[])
  
  /*useEffect(()=>{
    console.log(history,"HISTORY")
    if(window.location.pathname !== '/'){
      history.push("/homepage")
      window.history.replaceState(null,"","/")
    } else{
      const reloadUrl =  sessionStorage.getItem("pagelocation");
      if(reloadUrl !== null){
        if(app===ADMIN_APP &&reloadUrl!=='/homepage'){
        retryApiPost("/commonEndpoints/v1/userValidation",{
          page:'Administration'
        }, {
          headers: {
            'Authorization': tokenId,
          }
        }).then((res)=>{
          const accessAdmin = res.data.status
          if(accessAdmin === true){
            setAppInternalState(ADMIN_APP)
            setAdminAppAccess(true)
            setNotificationDelay(res.data.adminNotificationTime)
            const groupByRoles = groupBy(({rollName})=>rollName,res?.data?.permissionList)
            setRoles(Object.keys(groupByRoles))
            setPermissionPagesArray(res?.data?.permissionList)
            // const permissionList = pluck('name',res?.data?.userRole)
            // setPermissionArray(permissionList)
            // const rolePagesArray = res?.data?.userRole?.map(({name,permissions})=>(permissions?{[name]:pluck('name',permissions)}:{}))
            // const rolePagesObject = rolePagesArray?.reduce((acc,curVal)=>({...acc,...curVal}))
            // setPermissionPagesArray(rolePagesObject)

            // adminPages.some(({accessible,route})=>{
            //   if(accessible){
            //     history.replace({pathname:route})
            //     return false
            //   }return true
            // })

          }            else{
            setLoginRedirect(true)
          }
        }).catch(()=>{setLoginRedirect(true)})}
        else if(app===DASHBOARD_APP&&reloadUrl!=='/homepage'){
          retryApiPost("/commonEndpoints/v1/userValidation",{
            page:'Operational Dashboard'
          },{
            headers: {
              'Authorization': tokenId,
            }
          }).then((res)=>{
            const accessDash = res.data.status
            if(accessDash === true){
              setAppInternalState(DASHBOARD_APP)
              setDashboardAppAccess(true)
              const groupByRoles = groupBy(({rollName})=>rollName,res?.data?.permissionList)
              setRoles(Object.keys(groupByRoles))
              setPermissionPagesArray(res?.data?.permissionList)
              // const permissionList = pluck('name',res?.data?.userRole)
              // setPermissionArray(permissionList)
              // const rolePagesArray = res?.data?.userRole?.map(({name,permissions})=>(permissions?{[name]:pluck('name',permissions)}:{}))
              // const rolePagesObject = rolePagesArray?.reduce((acc,curVal)=>({...acc,...curVal}))
              // setPermissionPagesArray(rolePagesObject)
              // // adminPages.some(({accessible,route})=>{
              //   if(accessible){
              //     history.replace({pathname:route})
              //     return false
              //   }return true
              // })

            }
            else{
              setLoginRedirect(true)
            }
          }).catch(()=>setLoginRedirect(true))}
        else if(app===REPORT_APP&&reloadUrl!=='/homepage'){
            retryApiPost("/commonEndpoints/v1/userValidation",{
              page:'Reports'
            },{
              headers: {
                'Authorization': tokenId,
              }
            }).then((res)=>{
              const accessDash = res.data.status
              if(accessDash === true){
                setAppInternalState(REPORT_APP);
                setReportAppAccess(true);
                const groupByRoles = groupBy(({ rollName }) => rollName, res?.data?.permissionList)
                setRoles(Object.keys(groupByRoles))
                setPermissionPagesArray(res?.data?.permissionList)
              }
              else{
                setLoginRedirect(true)
              }
            }).catch(()=>setLoginRedirect(true))}
           
          history.push(reloadUrl)
      }

    }
  }, [history])*/

  useEffect(()=>{
    sessionStorage.setItem("pagelocation", pagelocation.pathname)
    if(pagelocation.pathname==='/homepage'||pagelocation.pathname==='/'||pagelocation.pathname==='/login'){
      setInitialLoad(false)
    }
    if(pagelocation.pathname==='/homepage'){
      adminAppAccess&&setAdminAppAccess(false)
      dashboardAppAccess&&setDashboardAppAccess(false)
      reportAppAccess&&setReportAppAccess(false)

    }
    //sessionStorage.setItem("appName", app)
  }, [pagelocation])
  

  useLayoutEffect(() => {
    hitSubUserTypeAPI();
  }, []);

  useEffect(()=>{
    if(usersUid){
      hitSpecialityTypeAPI();
      const adminDataURls = [
        '/hospitalEndpoints/v1/addHospitalWithUserGroup',
        '/adminUserEndpoints/v1/updateHospital',
        '/adminUserEndpoints/v1/updateHospitalStatus',
        // '/adminUserEndpoints/v1/updateHospitalStatus',
        '/hospitalEndpoints/v1/updateHospitalSimulate',
        '/uploadEndpoints/v1/userUploadCSV',
        '/adminUserEndpoints/v1/submitPendingChanges',
        // '/adminUserEndpoints/v1/updateUserGroup',
        '/adminUserEndpoints/v1/submitApprovedChanges',
        '/adminUserEndpoints/v1/reactivateUser',
        '/uploadEndpoints/v1/upload/csv/patient',
        'adminUserEndpoints/v1/updatePatient',
        '/adminUserEndpoints/v1/mergePatient',
        '/downloadEndpoints/v1/patient/chat/history/download',
        // '/adminUserEndpoints/v1/UpdateAdminUser',
        '/adminUserEndpoints/v1/postAlert',
        '/adminUserEndpoints/v1/getSystemAlerts',
        '/adminUserEndpoints/v1/updateConfigurations',
        '/commonEndpoints/v1/resetPassword'
      ]
      const userGroupApi = [
        '/adminUserEndpoints/v1/updateUserGroup',
        '/adminUserEndpoints/v1/UpdateAdminUser',
        '/userEndpoints/v1/registerUser',
        '/commonEndpoints/v1/adminLogin',
        'commonEndpoints/v1/logout'
      ]
      const idInterceptor = API.interceptors.request.use(function(config){
        const {data,url} = config;
        if(adminDataURls.includes(url)){
          return ({...config,data:{...data,
            user: {
              id: usersUid,
              osType: "Website",
              browserName: browser.name,
              browserVersion: navigator.appVersion,
            }
          }})
        }else if(userGroupApi.includes(url)){
          return ({...config,data:{...data,
              osType: "Website",
              browserName: browser.name,
              browserVersion: navigator.appVersion,
          }})
        }
        return config;
      },null,{runWhen({url}){
        return [...adminDataURls,...userGroupApi].includes(url)
      }})
      return () => {
        API.interceptors.request.eject(idInterceptor)
      }
    }
  },[usersUid])

  useEffect(()=>{
    let dataBase,timerId;
    if(Boolean(userId)&&Boolean(firebaseId)&&adminAppAccess&&Boolean(notificationDelay)){
        try{
        let childAddedExecution = false;
        //dataBase = Firebase_APP.database(REACT_APP_databaseURL)
        dataBase = /*firebase.database()*/getDatabase();
        /*dataBase.ref(`adminNotification/${firebaseId}`).once('value',*/
        onValue(ref(dataBase,`adminNotification/${firebaseId}`),(snapshot)=>{
          //console.log('FIREBASE',firebaseId,'Once_value',snapshot?.val?.())
          childAddedExecution = true
          setInitialLoad(true)
          setNotificationTime(false)
          if(!initialLoad){
          let alertsListSnapshot = snapshot.val(),alertsListSnapshot_1;
          if(alertsListSnapshot){
            alertsListSnapshot_1=mapObjIndexed((val,key)=>{
            if(val?.Patient){
              if(val?.message.search(/minutes$/)===-1){
                const {inviteTime} = JSON.parse(val?.Patient);
                const {serverTime} = val
                const diffminutes = moment(serverTime).diff(inviteTime,'minutes')
                // let diffminutesmultipleoften = parseInt(diffminutes/10)*10
                let messageValue = val?.message.replace(
                  /[\w\d.:-]*(?=\[UTC\])\[UTC\]/g,
                  function() {
                    return moment(arguments[0].replace('[UTC]','')).format('MMM DD YYYY, hh:mm A')
                  }
                ).replace(/[0-9]+$/,function(){
                  return moment(Number(arguments[0])).format('MMM DD YYYY, hh:mm A')
                })
                if(messageValue.search(/minutes$/)!==-1){
                  messageValue = messageValue.replace(/\d+(?= minutes$)/,diffminutes)
                }
                else{
                  messageValue = messageValue.concat(` ${diffminutes} minutes`)
                }
                //dataBase.ref(`adminNotification/${firebaseId}`).update({[key]:{...val,message:messageValue}})
                update(ref(dataBase,`adminNotification/${firebaseId}`),{[key]:{...val,message:messageValue}})
                return {...val,message:messageValue}
              }
              return val
            }else{
              return val
            }
          },alertsListSnapshot)
          if(location.pathname==='/app/notifications'){
            snapshot.forEach(childSnapshot=>{
              update(query(ref(dataBase,`adminNotification/${firebaseId}`),orderByKey()),{read:true})
              //const [,,patientIDPath] = childSnapshot?.ref?.path?.pieces_
              //dataBase.ref(`adminNotification/${firebaseId}/${patientIDPath}`).update({read:true})
            })
            setAlertsList(alertsListSnapshot_1)
          }else{
          const unreadAlertlistSnapshot = reject((val)=>val?.read,alertsListSnapshot_1)
          const readAlertlistSnapshot = filter((val)=>val?.read,alertsListSnapshot_1)
          setAlertsList(readAlertlistSnapshot)
          setUnReadAlertsList(unreadAlertlistSnapshot)
        }}}},{onlyOnce:true})
        /*dataBase.ref(`adminNotification/${firebaseId}`).on('value',*/
        onValue(ref(dataBase,`adminNotification/${firebaseId}`),(snapshot)=>{
         // console.log('FIREBASE',firebaseId,"value",snapshot?.val?.())
          let notificationList = snapshot.val();
          if(notificationList){
          /*notificationList=map((val,key)=>{
            if(val?.Patient){
              const {inviteTime} = JSON.parse(val?.Patient);
              const diffminutes = moment().diff(inviteTime,'minutes')
              let diffminutesmultipleoften = parseInt(diffminutes/10)*10
              return {...val,message:`${val?.message.replace(
                /[\w\d\.:-]*(?=\[UTC\])\[UTC\]/g,
                function() {
                  return moment(arguments[0].replace('[UTC]','')).format('DD MMM YYYY hh:mm a')
                }
              )} -:${diffminutesmultipleoften?diffminutesmultipleoften:'10'} minutes`}
            }else{
              return val
            }
          },notificationList)*/
            clearInterval(timerId)
          timerId=setInterval(()=>{
            forEachObjIndexed((val,key)=>{
              let {Patient,serverTime} = val;
              if(Patient){
              const {inviteTime} = JSON.parse(Patient)
              //if((Math.round(new Date().getTime()/1000)-Math.round(inviteTime/1000))%600===0){
                if(moment().diff(serverTime||inviteTime,'seconds')%(notificationDelay*60)===0){
                  const diffminutes = moment().diff(inviteTime,'minutes');
                  let messageValue = val?.message.replace(
                    /[\w\d.:-]*(?=\[UTC\])\[UTC\]/g,
                    function() {
                      return moment(arguments[0].replace('[UTC]','')).format('MMM DD YYYY, hh:mm A')
                    }
                  ).replace(/[0-9]+$/,function(){
                    return moment(Number(arguments[0])).format('MMM DD YYYY, hh:mm A')
                  })
                  if(messageValue.search(/minutes$/)!==-1){
                    messageValue = messageValue.replace(/\d+(?= minutes$)/,diffminutes)
                  }
                  else{
                    messageValue = messageValue.concat(` ${diffminutes} minutes`)
                  }
                  //dataBase.ref(`adminNotification/${firebaseId}/${key}`).update({read:false})
                  if(location.pathname==='/app/notifications'){
                  setUnReadAlertsList((unreadAlertsList)=>dissoc(key,unreadAlertsList))
                  setAlertsList((alertsList)=>assoc(key,{...val,read:true,message:messageValue},alertsList))
                  }else{
                  setUnReadAlertsList((unreadAlertsList)=>assoc(key,{...val,read:false,message:messageValue},unreadAlertsList))
                  setAlertsList((alertsList)=>dissoc(key,alertsList))
                  }
                  setShowNotification({...val})
                  //dataBase.ref(`adminNotification/${firebaseId}`).update({[key]:{...val,message:messageValue}})
                  update(ref(dataBase,`adminNotification/${firebaseId}`),{[key]:{...val,message:messageValue}})
              }
            }},notificationList)
          },1000)
        }})
        /*dataBase.ref(`adminNotification/${firebaseId}`).on('child_added'*/
        onChildAdded(ref(dataBase,`adminNotification/${firebaseId}`),(snapshot)=>{
          //setAlertsList([...alertsList,snapshot.val()])
          if(childAddedExecution){
          if(location.pathname==='/app/notifications'){
            let originalSnapShotObject = snapshot.val()
            let snapShotObject = snapshot.val();
            //let {serverTime} = snapShotObject
            if(snapShotObject?.Patient){
              const {inviteTime} = JSON.parse(snapShotObject?.Patient);
              const diffminutes = moment().diff(inviteTime,'minutes')
              let messageValue = snapShotObject?.message.replace(
                /[\w\d.:-]*(?=\[UTC\])\[UTC\]/g,
                function() {
                  return moment(arguments[0].replace('[UTC]','')).format('MMM DD YYYY, hh:mm A')
                }
              ).replace(/[0-9]+$/,function(){
                return moment(Number(arguments[0])).format('MMM DD YYYY, hh:mm A')
              })
              if(messageValue.search(/minutes$/)!==-1){
                messageValue = messageValue.replace(/\d+(?= minutes$)/,diffminutes)
              }
              else{
                messageValue = messageValue.concat(` ${diffminutes} minutes`)
              }
              snapShotObject = {...snapShotObject,message:messageValue}
            }
            /*snapShotObject=map((val,key)=>{
              if(val?.Patient){
                const {inviteTime} = JSON.parse(val?.Patient);
                const diffminutes = moment().diff(inviteTime,'minutes')
                let diffminutesmultipleoften = parseInt(diffminutes/10)*10
                return {...val,message:`${val?.message.replace(
                  /[\w\d\.:-]*(?=\[UTC\])\[UTC\]/g,
                  function() {
                    return moment(arguments[0].replace('[UTC]','')).format('DD MMM YYYY hh:mm a')
                  }
                ).replace(/[0-9]+$/,function(){
                  return moment(Number(arguments[0])).format('DD MMM YYYY hh:mm a')
                })} - ${diffminutes} minutes`}
              }else{
                return val
              }
            },snapShotObject)*/
            // if(snapshot.key === 'Remote Provider notification'){
            //   snapShotObject=Object.assign(snapShotObject,{time:new Date().getTime()})
            // }
            setAlertsList((alertsList)=>({...alertsList,[snapshot.key]:{...snapShotObject,read:true}}))
            //dataBase.ref(`adminNotification/${firebaseId}`).update({[snapshot.key]:{...snapShotObject,read:true}})
            update(query(ref(dataBase,`adminNotification/${firebaseId}`),orderByKey()),{read:true})
            setShowNotification(originalSnapShotObject)
          }
          else{
            let snapShotObject = snapshot.val();
            let originalSnapShotObject = snapshot.val()
            //let {serverTime} = snapShotObject
            if(snapShotObject?.Patient){
              const {inviteTime} = JSON.parse(snapShotObject?.Patient);
              const diffminutes = moment().diff(inviteTime,'minutes')
              let messageValue = snapShotObject?.message.replace(
                /[\w\d.:-]*(?=\[UTC\])\[UTC\]/g,
                function() {
                  return moment(arguments[0].replace('[UTC]','')).format('MMM DD YYYY, hh:mm A')
                }
              ).replace(/[0-9]+$/,function(){
                return moment(Number(arguments[0])).format('MMM DD YYYY, hh:mm A')
              })
              if(messageValue.search(/minutes$/)!==-1){
                messageValue = messageValue.replace(/\d+(?= minutes$)/,diffminutes)
              }
              else{
                messageValue = messageValue.concat(` ${diffminutes} minutes`)
              }
              snapShotObject = {...snapShotObject,message:messageValue}
            }
            /*let snapShotObjectFinal=map((val,key)=>{
              if(val?.Patient){
                const {inviteTime} = JSON.parse(val?.Patient);
                const diffminutes = moment().diff(inviteTime,'minutes')
                let diffminutesmultipleoften = parseInt(diffminutes/10)*10
                const message = `${val?.message.replace(
                  /[\w\d\.:-]*(?=\[UTC\])\[UTC\]/g,
                  function() {
                    return moment(arguments[0].replace('[UTC]','')).format('DD MMM YYYY hh:mm a')
                  }
                ).replace(/[0-9]+$/,function(){
                  return moment(Number(arguments[0])).format('DD MMM YYYY hh:mm a')
                })} - ${diffminutes} minutes`
                return {...val,message}
              }else{
                return val
              }
            },snapShotObject)*/
            // if(snapshot.key === 'Remote Provider notification'){
            //   snapShotObject=Object.assign(snapShotObject,{time:new Date().getTime()})
            // }
            setUnReadAlertsList((unreadAlertsList)=>({...unreadAlertsList,[snapshot.key]:snapShotObject}))
            //dataBase.ref(`adminNotification/${firebaseId}`).update({[snapshot.key]:{...snapShotObject}})
            update(ref(dataBase,`adminNotification/${firebaseId}`),{[snapshot.key]:{...snapShotObject}})
          setShowNotification(originalSnapShotObject)
          }}
          // const AlertsList = snapshot.val();
          // setAlertsList(AlertsList)
          //setAlertsList([...alertsList,snapshot.val()])
      })
      /*dataBase.ref(`adminNotification/${firebaseId}`).on('child_changed',(snapshot)=>{
//setAlertsList([...alertsList,snapshot.val()])
        if(childAddedExecution){
        if(location.pathname==='/app/notifications'){
          let originalSnapshotobject = snapshot.val()
          let snapShotObject = snapshot.val();
          snapShotObject=map((val,key)=>{
            if(val?.Patient){
              const {inviteTime} = JSON.parse(val?.Patient);
              const diffminutes = moment().diff(inviteTime,'minutes')
              let diffminutesmultipleoften = parseInt(diffminutes/10)*10
              return {...val,message:`${val?.message.replace(
                /[\w\d\.:-]*(?=\[UTC\])\[UTC\]/g,
                function() {
                  return moment(arguments[0].replace('[UTC]','')).format('DD MMM YYYY hh:mm a')
                }
              ).replace(/[0-9]+$/,function(){
                return moment(Number(arguments[0])).format('DD MMM YYYY hh:mm a')
              })} -:${diffminutesmultipleoften?diffminutesmultipleoften:'10'} minutes`}
            }else{
              return val
            }
          },snapShotObject)
          // if(snapshot.key === 'Remote Provider notification'){
          //   snapShotObject=Object.assign(snapShotObject,{time:new Date().getTime()})
          // }
          setAlertsList((alertsList)=>({...alertsList,[snapshot.key]:{...snapShotObject,read:true}}))
          setUnReadAlertsList((unreadAlertsList)=>dissoc(snapshot.key,unreadAlertsList))
          dataBase.ref(`adminNotification/${firebaseId}`).update({[snapshot.key]:{...originalSnapshotobject,read:true}})
          if(!snapshot.val().read){
            setShowNotification(originalSnapshotobject)
          }
        }
        else{
          let snapShotObjectOriginal = snapshot.val();
          let snapShotObject=map((val,key)=>{
            if(val?.Patient){
              const {inviteTime} = JSON.parse(val?.Patient);
              const diffminutes = moment().diff(inviteTime,'minutes')
              let diffminutesmultipleoften = parseInt(diffminutes/10)*10
              return {...val,message:`${val?.message.replace(
                /[\w\d\.:-]*(?=\[UTC\])\[UTC\]/g,
                function() {
                  return moment(arguments[0].replace('[UTC]','')).format('DD MMM YYYY hh:mm a')
                }
              ).replace(/[0-9]+$/,function(){
                return moment(Number(arguments[0])).format('DD MMM YYYY hh:mm a')
              })} -:${diffminutesmultipleoften?diffminutesmultipleoften:'10'} minutes`}
            }else{
              return val
            }
          },snapShotObjectOriginal)
          // if(snapshot.key === 'Remote Provider notification'){
          //   snapShotObject=Object.assign(snapShotObject,{time:new Date().getTime()})
          // }
          if(!snapshot.val().read){
          setUnReadAlertsList((unreadAlertsList)=>({...unreadAlertsList,[snapshot.key]:snapShotObject}))
          setAlertsList((alertsList)=>dissoc(snapshot.key,alertsList))
          setShowNotification(snapShotObjectOriginal)
          }else if(snapshot.val().read){
            setAlertsList((alertsList)=>({...alertsList,[snapshot.key]:snapShotObject}))
            setUnReadAlertsList((unreadAlertsList)=>dissoc(snapshot.key,unreadAlertsList))
            //setUnReadAlertsList((unreadAlertsList)=>({...unreadAlertsList,[snapshot.key]:snapShotObject}))
          }
        //setShowNotification(snapShotObject)
        }}
        // const AlertsList = snapshot.val();
        // setAlertsList(AlertsList)
        //setAlertsList([...alertsList,snapshot.val()])
    })*/
      /*dataBase.ref(`adminNotification/${firebaseId}`).on('child_removed'*/
      onChildRemoved(ref(dataBase,`adminNotification/${firebaseId}`),(snapshot)=>{
        const removedKey = snapshot.key;
        if(removedKey === 'Remote Provider notification'){
          setUnReadAlertsList(unreadAlertsList=>{
            return dissoc('Remote Provider notification',unreadAlertsList)
          })
          setAlertsList(alertsList=>{
            return dissoc('Remote Provider notification',alertsList)
          })
        }else{
        setUnReadAlertsList(unreadAlertsList=>{
          return dissoc(removedKey,unreadAlertsList)
        })
        setAlertsList(alertsList=>{
          return dissoc(removedKey,alertsList)
        })
    }})
    }
      catch(error){
          
      }
    }return ()=>{
      timerId&&clearInterval(timerId);
      if(dataBase){
        off(ref(dataBase,`adminNotification/${firebaseId}`))
        /*dataBase.ref(`adminNotification/${firebaseId}`).off('value')
        dataBase.ref(`adminNotification/${firebaseId}`).off('child_added')
        dataBase.ref(`adminNotification/${firebaseId}`).off('child_changed')
        dataBase.ref(`adminNotification/${firebaseId}`).off('child_removed')*/
      }
    }
  },[userId,firebaseId,adminAppAccess,location,notificationDelay])

  // useEffect(()=>{
  //    const allAlertsList = {...alertsList,...unreadAlertsList}
  //    const timerID = window.setInterval(()=>{
  //      forEachObjIndexed(allAlertsList,(value,key)=>{})
  //    },1000)
  //    return ()=>{
  //      clearInterval(timerID)
  //    }
  // },[alertsList,unreadAlertsList])

  /*useEffect(() => {
    //sessionStorage.setItem("userId", String(userId));
    //sessionStorage.setItem("app", String(app));
    //sessionStorage.setItem("userType", String(userType));
    //sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
    //sessionStorage.setItem("tokenId", String(tokenId));
    //sessionStorage.setItem("refreshId", String(refreshId));
    //sessionStorage.setItem("userPermission", String(userPermission));
    //sessionStorage.setItem("userEmailId",JSON.stringify(userSessionEmail));
    //sessionStorage.setItem("firebaseID",String(firebaseId));
    //sessionStorage.setItem("usersuid",usersUid);
    //sessionStorage.setItem("loginUserEmail", loginUserEmail);
    //sessionStorage.setItem("providerUserId", String(providerUserId));
    //sessionStorage.setItem("logoutUserToken", String(logoutUserToken));
    //sessionStorage.setItem("providerUserToken", String(providerUserToken));
    //sessionStorage.setItem("providerUserEmail", String(providerUserEmail));
    //sessionStorage.setItem("providerUserPassword", String(providerUserPassword));
    //sessionStorage.setItem("acceptedProvider", Boolean(acceptedProvider));
    //sessionStorage.setItem("authToken", String(authToken));
    //sessionStorage.setItem("sessionFirebaseToken", String(sessionFirebaseToken));
    //sessionStorage.setItem("passwordExpired", Boolean(passwordExpired));
    //sessionStorage.setItem("passwordExpiredToken", String(passwordExpiredToken));
    //if(!Boolean(userId)){
    //  sessionStorage.removeItem("userId")
    //}
    //if(!Boolean(app)){
    //  sessionStorage.removeItem("app")
    //}
    //if(!Boolean(userDetails)){
    //  sessionStorage.removeItem("userDetails")
    //}
    //if(!Boolean(tokenId)){
    //  sessionStorage.removeItem("tokenId")
    //}
    //if(!Boolean(refreshId)){
    //  sessionStorage.removeItem("refreshId")
    //}
    //if(!Boolean(userPermission)){
    //  sessionStorage.removeItem("userPermission")
    //}
    //if(!Boolean(firebaseId)){
    //  sessionStorage.removeItem("firebaseID")
    //}
    //if(!Boolean(usersUid)){
    //  sessionStorage.removeItem("usersuid")
    //}
    //if(!Boolean(loginUserEmail)){
    //  sessionStorage.removeItem("loginUserEmail")
    //}
    //if(!Boolean(userType)){
    //  sessionStorage.removeItem("userType")
    //}
    // if(isEmpty(userSessionEmail)){
    //   sessionStorage.removeItem("userEmailId")
    // }
    //if(!Boolean(logoutUserToken)){
    //  sessionStorage.removeItem("logoutUserToken")
    //}
    //if(!Boolean(authToken)){
    //  sessionStorage.removeItem("authToken")
    //}
    //if(!Boolean(providerUserId)){
    //  sessionStorage.removeItem("providerUserId")
    //}
    //if(!Boolean(providerUserEmail)){
    //  sessionStorage.removeItem("providerUserEmail")
    //}
    //if(!Boolean(providerUserPassword)){
    //  sessionStorage.removeItem("providerUserPassword")
    //}
    //if(!Boolean(providerUserToken)){
    //  sessionStorage.removeItem("providerUserToken")
    //}
    //if(!Boolean(passwordExpiredToken)){
    //  sessionStorage.removeItem('passwordExpiredToken')
    //}
  }, [userId, app, userDetails, tokenId, refreshId, userPermission, firebaseId,usersUid, providerUserId, providerUserToken, providerUserEmail, providerUserPassword, logoutUserToken, loginUserEmail, authToken, passwordExpiredToken,passwordExpired]);*/
  useEffect(() => {
    setPage(location.pathname);
  }, [location]);

  const alertClose = () => {
    setAlertOpen(false);
    setAlertText("");
    setAlertType("error");
  };
  const alert = (text, type) => {
    setAlertText(text);
    type && setAlertType(type);
    setAlertOpen(true);
  };

  const adminPages = [
    {
      name: "Customer",
      route: "/app/customer",
      icon: <Customer />,
      accessible:CustomerScreenAccess
    },
    {
      name: "Hospital",
      route: "/app/hospital",
      icon: <Hospital />,
      accessible:hospitalManagementAccess
    },
    {
      name: "Provider",
      route: "/app/approvals",
      icon: <Provider />,
      accessible:providerManagementAccess
    },
    {
      name: "Patient",
      route: "/app/patients",
      icon: <Patient />,
      accessible:patientManagementAccess
    },
    {
      name: "eConsult Record",
      route: "/app/patients/medicalrecord",
      icon: <MedicalRecord />,
      accessible:eConsultRecordAccess
    },
    {
      name: "Admin Users",
      route: "/app/adminusers",
      icon: <Admin />,
      accessible:adminUserManagementAccess
    },
    {
      name: "System Alert",
      route: "/app/alert",
      icon: <SystemAlert />,
      accessible:systemAlertAccess
    },
    {
      name: "Clinician Signout",
      route: "/app/clinician",
      icon: <ClinicianSignOut />,
      accessible:clinicianAccess
    },
    {
      name: "Permission",
      route: "/app/permissions",
      icon: <Permissions />,
      accessible:permissionAccess
    }
  ];
  const triagePages = [
    {
      name: "Patient Management",
      route: "/app/patients",
      icon: <HomeWorkIcon />,
    } /* {
            name: 'Patient Upload',
            route: '/app/patientsupload',
            icon:<AccessibilityIcon/>
        }*/,
  ];

  const dashboardPages = [
    {
      name: "Dashboard",
      route: "/dashboard",
      icon: <DashboardPageIcon />,
      accessible:DashboardScreenPermission
    },
    {
      name: "Active Consults",
      route: "/dashboard/active_consults",
      icon: <ActiveConsultIcon />,
      accessible:ActiveConsultsScreenPermission
    },
    {
      name: "Pending Consults",
      route: "/dashboard/pending_consults",
      icon: <PendingConsultIcon />,
      accessible:PendingConsultsScreenPermission
    },
    {
      name: "Wards",
      route: "/dashboard/wards",
      icon: <WardsIcon />,
      accessible:WardsScreenPermission
    },
    {
      name: "Remote Staffing",
      route: "/dashboard/staffing",
      icon: <StaffingIcon />,
      accessible:StaffingScreenPermission
    } /*  {
            name: 'Reports',
            route: '/dashboard/reports',
            icon:<AssessmentIcon/>
        }, */,
  ];

  
  const reportsPages = [
    {
      name: "Hospital Metrics",
      route: "/app/hospitalreportdashboard",
      icon: <DashboardIcon />,
      accessible:HospitalReportScreenAccess
    },
    {
      name: "Consult Metrics",
      route: "/app/ocreportdashboard",
      icon: < PeopleAltOutlinedIcon />,
      accessible:QcReportScreenAccess
    }
  ];
  
  useEffect(()=>{
    if(!isEmpty(permissionPagesList)){
      if(pagelocation.pathname==='/homepage'){
      if(app==='adminApp'){
        adminPages.some(({accessible,route})=>{
        if(accessible){
          history.replace({pathname:route})
          return true
        }return false
      })
      }else if(app==='dashboardApp'){
       dashboardPages.some(({accessible,route})=>{
        if(accessible){
          history.replace({pathname:route})
          return true
        }return false
      })
      }
      else if(app==='reportApp'){
        history.replace('/app/reports')
      //   emptyPageRedirect=reportsPages.some(({accessible,route})=>{
      //   if(accessible){
      //     history.replace({pathname:route})
      //     return true
      //   }return false
      // })
     }    
    }
  }
  },[permissionPagesList])

  const TokenHandlingObject = useMemo(()=>{
    return {
      tokenId,refreshId,setTokenId,setRefreshId
    }
  },[tokenId,refreshId,setTokenId,setRefreshId])

  const renderSwitch = useMemo(
    () => (
      <div className="rootPadding">
        <CustomAlert
          open={alertOpen}
          handleClose={alertClose}
          text={alertText}
          type={alertType}
        />
        <Switch>
          {/* {userId !== null ? ():()} */}
          <Route path="/emptyPage" render={()=><div/>}/>
          {userId ? (<>
              <>{appinternalState === ADMIN_APP && adminAppAccess && userType !== "T" && (<TokenContext.Provider value ={TokenHandlingObject}><SpecialityTypeList.Provider value={specialityTypeList}><AdminApp
                    app={app}
                    userType={userType}
                    userId={userId}
                    alert={alert}
                    setUserId={setUserId}
                    tokenId={tokenId}
                    setTokenId={setTokenId}
                    refreshId={refreshId}
                    setRefreshId={setRefreshId}
                    remoteProviderTypeList={remoteProviderTypeList}
                    userDetails={userDetails}
                    setLogoutUserToken={setLogoutUserToken}
                    logoutUserToken={ logoutUserToken}
                    sessionFirebaseToken={sessionFirebaseToken}
                  /></SpecialityTypeList.Provider></TokenContext.Provider>)}</>

                <>{appinternalState === REPORT_APP && reportAppAccess && (<ReportApp
                    app={app}
                    userType={userType}
                    userId={userId}
                    alert={alert}
                    setUserId={setUserId}
                    tokenId={tokenId}
                    setTokenId={setTokenId}
                    refreshId={refreshId}
                    setRefreshId={setRefreshId}
                    remoteProviderTypeList={remoteProviderTypeList}
                    userDetails={userDetails}
                    setLogoutUserToken={setLogoutUserToken}
                    logoutUserToken={ logoutUserToken}
                    sessionFirebaseToken={sessionFirebaseToken}
                  />)}</>
                  <>{
                    appinternalState === ADMIN_APP && userType === "T" && (<TriageApp
                      app={app}
                      userId={userId}
                      setUserId={setUserId}
                      alert={alert}
                      tokenId={tokenId}
                      setTokenId={setTokenId}
                      refreshId={refreshId}
                      setRefreshId={setRefreshId}
                      setLogoutUserToken={setLogoutUserToken}
                      logoutUserToken={ logoutUserToken}/>)
                  }</>
                  <>{
                    appinternalState === DASHBOARD_APP && dashboardAppAccess && (
                      <DashboardApp
                      app={app}
                      userId={userId}
                      setUserId={setUserId}
                      alert={alert}
                      tokenId={tokenId}
                      setTokenId={setTokenId}
                      refreshId={refreshId}
                      setRefreshId={setRefreshId}
                      setLogoutUserToken={setLogoutUserToken}
                      logoutUserToken={ logoutUserToken}
                      sessionFirebaseToken={sessionFirebaseToken}
                    />
                    )
                  }</></>):(<div style={{ fontSize: "22px" }}>
                  Please{" "}
                  <a
                    style={{
                      textDecoration: "underline",
                      color: "#9B51E0",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      // history.push("/login");
                      // setLogoutUserToken(null)
                      sessionStorage.clear();
                      window.location.reload();
                    }}
                  >
                    Login
                  </a>{" "}
                  to access the application
                </div>)}
        </Switch>
      </div>
    ),
    [alertOpen, alertText, tokenId,refreshId,alertType, alert, userId, userType, userDetails,appinternalState,adminAppAccess,dashboardAppAccess,reportAppAccess,TokenHandlingObject]
  );

  //console.log(appinternalState,adminAppAccess,userType,"DETAILS_SECTION")
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Switch>
        {/* <Route
          path="/"
          exact
          component={() => (
          <Index
            redirectionURL={
            userId ? "/homepage"
            : "/login"
            }
          />
          )}
          /> */}
        <Route
          path="/login"
          exact
          render={() => {if(userId){
            return (<Redirect to="/homepage" />)
          }
            return (
            <div style={{ display: "grid", height: "100vh" }}>
              <LoginScreen
                alert={alert}
                userId={userId}
                setUserId={setUserId}
                app={app}
                setApp={setApp}
                userType={userType}
                setUserType={setUserType}
                setUserDetails={setUserDetails}
                tokenId={tokenId}
                setTokenId={setTokenId}
                refreshId={refreshId}
                setRefreshId={setRefreshId}
                setUserPermission={setUserPermission}
                userPermission={userPermission}
                //setUserSessionEmail={setUserSessionEmail}
                //userSessionEmail={userSessionEmail}
                //setLoginFooter={setLoginFooter}
                setFirebaseId={setFirebaseId}
                setProviderUserLogin={setProviderUserLogin}
                setProviderUserId={setProviderUserId}
                setProviderUserToken={setProviderUserToken}
                setProviderUserEmail={setProviderUserEmail}
                setProviderUserPassword={setProviderUserPassword}
                setAcceptedProvider={setAcceptedProvider}
                setLogoutUserToken={setLogoutUserToken}
                setLoginUserEmail={setLoginUserEmail}
                //setAuthToken={setAuthToken}
                setPasswordExpired={setPasswordExpired}
                setPasswordExpiredToken={setPasswordExpiredToken}
              />
            </div>
          )}}
        />
        <Route
          path="/timeline"
          exact
          render={(props)=> <TimelineComponent alert={alert}
                adminPages={adminPages}
                dashboardPages={dashboardPages}
                userId={userId}
                setUserId={setUserId}
                tokenId={tokenId}
                setTokenId={setTokenId}
                refreshId={refreshId}
                setRefreshId={setRefreshId}
                userPermission={userPermission}
                setUserPermission={setUserPermission}
                app={app}
                setApp={setApp}
                userType={userType}
                setUserType={setUserType}
                setAppInternalState={setAppInternalState}
                setAdminAppAccess={setAdminAppAccess}
                setReportAppAccess={setReportAppAccess}
                setDashboardAppAccess={setDashboardAppAccess}
                setUserDetails={setUserDetails} 
                setUsersUid={setUsersUid} 
                setFirebaseId={setFirebaseId}
                logoutUserToken={logoutUserToken} {...props} 
                setLogoutUserToken={setLogoutUserToken}
                />}
                />

        <Route path="/app/forgotpassword">
          <ForgotPassword
            setUserId={setUserId}
            userId={userId}
            tokenId={tokenId}
            setTokenId={setTokenId}
            refreshId={refreshId}
            setRefreshId={setRefreshId}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
            setPasswordExpired={setPasswordExpired}
            passwordExpired={passwordExpired}
            setPasswordExpiredToken={setPasswordExpiredToken}
            passwordExpiredToken={passwordExpiredToken}
            loginUserEmail={loginUserEmail}
          /> </Route>
         <Route path="/app/termsandconditions"
        isCurrentApp={app === ADMIN_APP && userType !== "T"}
        component={() => (
          <TermsAndCondition
            setUserId={setUserId}
            userId={userId}
            tokenId={tokenId}
            setTokenId={setTokenId}
            refreshId={refreshId}
            setRefreshId={setRefreshId}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
            setProviderUserLogin={setProviderUserLogin}
            providerUserLogin={providerUserLogin}
            providerUserId={providerUserId}
            providerUserToken={providerUserToken}
            providerUserEmail={providerUserEmail}
            setProviderUserPassword={setProviderUserPassword}
            providerUserPassword={providerUserPassword}
            setFirebaseId={setFirebaseId}
            setUserPermission={setUserPermission}
            setProviderUserEmail={setProviderUserEmail}
          />
        )} />
        <Route path="/app/providerpassword" >
          <ProviderUserPassword
            setUserId={setUserId }
            userId={userId }
            //tokenId={tokenId}
            setTokenId={setTokenId }
            refreshId={refreshId }
            setRefreshId={setRefreshId }
           // userDetails={userDetails}
            setUserDetails={setUserDetails }
            setUserType={setUserType }
            providerUserLogin={providerUserLogin}
            providerUserToken={providerUserToken }
            providerUserEmail={providerUserEmail }
            setProviderUserPassword={setProviderUserPassword }
           // providerUserPassword={providerUserPassword}
            setFirebaseId={setFirebaseId }
            setUserPermission={setUserPermission }
            //setAcceptedProvider={setAcceptedProvider}
            acceptedProvider={acceptedProvider }
          />
        </Route>
        <Route path="/error" exact render={(props)=> <ErrorComponent />} />
        <Route path="/homepage" exact render={(props)=> <NavigationComponent alert={alert}
                adminPages={adminPages}
                dashboardPages={dashboardPages}
                userId={userId}
                setUserId={setUserId}
                tokenId={tokenId}
                setTokenId={setTokenId}
                refreshId={refreshId}
                setRefreshId={setRefreshId}
                userPermission={userPermission}
                setUserPermission={setUserPermission}
                app={app}
                setApp={setApp}
                userType={userType}
                setUserType={setUserType}
                setAppInternalState={setAppInternalState}
                setAdminAppAccess={setAdminAppAccess}
                setReportAppAccess={setReportAppAccess}
                setDashboardAppAccess={setDashboardAppAccess}
                logoutUserToken={logoutUserToken}
                setLogoutUserToken={setLogoutUserToken}
                sessionFirebaseToken={sessionFirebaseToken}
                setUserDetails={setUserDetails} setUsersUid={setUsersUid} setFirebaseId={setFirebaseId} {...props} /> } />
        <Route
        path="/app/changePassword"
        isCurrentApp={app === ADMIN_APP && userType !== "T"}
        component={() => (
          <ChangePasswordScreen
            setUserId={setUserId}
            userId={userId}
            tokenId={tokenId}
            setTokenId={setTokenId}
            refreshId={refreshId}
            setRefreshId={setRefreshId}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
            sessionFirebaseToken={sessionFirebaseToken}
          />
        )}
      />

      {/* <Route
        path="/app/hospitalreportdashboard"
        component={() => (
          <HospitalReportScreen
            setUserId={setUserId}
            userId={userId}
            tokenId={tokenId}
            setTokenId={setTokenId}
            refreshId={refreshId}
            setRefreshId={setRefreshId}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
          />
        )}
      />

    <Route
        path="/app/ocreportdashboard"
        component={() => (
          <OcReportScreen
            setUserId={setUserId}
            userId={userId}
            tokenId={tokenId}
            setTokenId={setTokenId}
            refreshId={refreshId}
            setRefreshId={setRefreshId}
            userDetails={userDetails}
            setUserDetails={setUserDetails}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
          />
        )}
      /> */}
 <Route
        path="/app/reports">
          <ReportExportsApp
            setUserId={setUserId}
            userId={userId}
            tokenId={tokenId}
            setTokenId={setTokenId}
            refreshId={refreshId}
            setUsersUid={setUsersUid}
            firebaseId={firebaseId}
            setFirebaseId={setFirebaseId}
            setRefreshId={setRefreshId}
            userDetails={userDetails}
            setUserPermission={setUserPermission}
            setUserDetails={setUserDetails}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
          />
        </Route>
      <Route
        path="/app/success"
        isCurrentApp={app === ADMIN_APP && userType !== "T"}
        component={() => (
          <SuccessScreen setUserId={setUserId} userId={userId} />
        )}
      />
        {Boolean(appinternalState) && PagesList[appinternalState]?.includes(page) &&  (
          <div>
            <Suspense
              fallback={
                <div
                  style={{
                    height: "80vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className="loader"></div>
                </div>
              }
            >
              <ResponsiveDrawer
                path={location.pathname}
                authUser={userId}
                shouldShowDrawer={(page !== LOGIN_SCREEN||page!=='/app/reports') && (app !== null||app===REPORT_APP)}
                firebaseId={firebaseId}
                setUserDetails={setUserDetails}
                setUserId={setUserId}
                setApp={setApp}
                setUserPermission={setUserPermission}
                setTokenId={setTokenId}
                setRefreshId={setRefreshId}
                setUserType={setUserType}
                setFirebaseId={setFirebaseId}
                setUsersUid={setUsersUid}
                pages={
                  app === ADMIN_APP ? userType !== "T" ? adminPages : triagePages : app === REPORT_APP ? reportsPages : dashboardPages
                }
                pageValue={page}
                app={app}
                onPageChange={(value) => {
                  history.push(value);
                }}
                children={renderSwitch}
                logoutUserToken={logoutUserToken}
                tokenId={tokenId}
                setLogoutUserToken={setLogoutUserToken}
                refreshId={refreshId}
              />
            </Suspense>
            <ToastAlertComponent />
            </div>
        )}
        <Route render={(props)=>{

          return (<RoutingComponent alert={alert}
            userId={userId}
            setUserId={setUserId}
            app={app}
            setApp={setApp}
            userType={userType}
            setUserType={setUserType}
            setUserDetails={setUserDetails}
            setUsersUid={setUsersUid}
            setFirebaseId={setFirebaseId}
            setUserPermission={setUserPermission}
            setRefreshId={setRefreshId}
            setTokenId={setTokenId} loginRedirect={loginRedirect} setLogoutUserToken={setLogoutUserToken} {...props} /> )
        }} />
        </Switch>
        {showSystemAlertModal && (
          <SystemAlertScreen
            handleClose={() => setSystemAlertShowModal(false)}
          />
        )}
        <SnackbarProvider>
        <NotificationComponent />
        </SnackbarProvider>
        <ProviderNotification
          userId={userId}
          setUserId={setUserId}
          setUserPermission={setUserPermission}
          setApp={setApp}
          userType={userType}
          setUserType={setUserType}
          setAppInternalState={setAppInternalState}
          setAdminAppAccess={setAdminAppAccess}
          setReportAppAccess={setReportAppAccess}
          setDashboardAppAccess={setDashboardAppAccess}
          setUserDetails={setUserDetails} 
          setUsersUid={setUsersUid} 
          setFirebaseId={setFirebaseId} 
          setRefreshId={setRefreshId}
          setTokenId={setTokenId}
          logoutUserToken={logoutUserToken}
          setLogoutUserToken={setLogoutUserToken}
          setSessionFirebaseToken={setSessionFirebaseToken}
        />
        {/* <SessionPopupComponent /> */}
        {/* <footer>
          <div className="footerSection">
            <div className="footerBody">
              <Typography variant="body1">
                <Link
                  href="https://www.omnicuremd.com/terms.html"
                  target="_blank"
                  className="footerText"
                  color="primary"
                >
                  Terms & Conditions
                </Link>
              </Typography>
              <Typography variant="body1">
                <Link
                  href="https://www.omnicuremd.com/contact.html"
                  target="_blank"
                  className="footerText"
                  color="primary"
                >
                  Contact Us
                </Link>
              </Typography>

              <Copyright />
            </div>
          </div>
        </footer> */}
      </div>
    </ThemeProvider>
  );
};

// const Index = ({ redirectionURL }) => {
//   return <Redirect to={redirectionURL} />;
// };

export default withRouter(App);