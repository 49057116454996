import React, { useState, useCallback, useEffect } from 'react';
import { DrawerComponent as SideBar, ApplicationBar } from 'components/common/SideBar';
import { Hidden } from '@material-ui/core';
import API from "./api";
import { useRecoilValue } from 'recoil';
import { PermissionCollection, usersID, firebaseIdGlobal as firebaseId } from "App";
//import {AlertListNotification, unReadNotificationList, DelayNotificationTime, ShowNotification} from "App";
import { assocPath,innerJoin } from 'ramda';
import CustomLoader from 'components/common/CustomLoader';
import { makeStyles } from "@material-ui/core/styles";
import {HealthMonitorApiCall} from "./components/common/HealthMonitor";
const useLoadStyles = makeStyles({
    Loading: {
        height: "100vh",
        justifyContent: "center",
        display: "flex",
        alignItems: "flex-start",
        position: "absolute",
        width: "100vw",
        backgroundColor:'#FFFFFF'
    },
});
const ReportApp = ({
    firebaseId: firebaseIdProp,
    setUserId,
    setUserDetails,
    setApp,
    setUserPermission,
    setTokenId,
    setRefreshId,
    setUserType,
    setFirebaseId,
    tokenId,
    refreshId,
    setUsersUid,
    setLogoutUserToken,
    logoutUserToken,
    sessionFirebaseToken }) => {
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(true);
    const [value, setValue] = useState(null);
    const [pages, setPages] = useState([]);
    const [reporConfigList, setReportConfigList] = useState([]);
    const usersUid = useRecoilValue(usersID);
    const Loadclasses = useLoadStyles();
    const firebaseuid = useRecoilValue(firebaseId);
    const permissionReports=useRecoilValue(PermissionCollection)
    // const resetDelayNotificationTime = useResetRecoilState(DelayNotificationTime)
    // const resetAlertListNotificationTime = useResetRecoilState(AlertListNotification)
    // const resetUnreadNotificationList = useResetRecoilState(unReadNotificationList)
    // const resetShowNotification = useResetRecoilState(ShowNotification)
    //const history = useHistory();
    const retryApiGet = (url, header) => {
        return API.get(url, assocPath(['headers', 'uid'], firebaseuid, header)).then((res) => {
            if (res?.headers?.userid === String(usersUid)) {
                return Promise.resolve(res)
            } else {
                throw new Error("Invalid Response")
            }
        }).catch(function (error) {
            if ([401, 500, 502, 504, 703, 704].includes(error?.response?.status)) {
                return new Promise(resolve => {
                    API.post("/userEndpoints/v1/renewIdToken", {
                        "token": refreshId
                    })
                        .then((res) => {
                            setTokenId(res.data.idToken)
                            if (!res.data.idToken) {
                                throw error
                            } else {
                                resolve(API.get(url, {
                                    ...header, headers: {
                                        Authorization: res.data.idToken,
                                        uid: firebaseuid
                                    }
                                }).then((res) => {
                                    if (res?.headers?.userid === String(usersUid)) {
                                        return Promise.resolve(res)
                                    } else {
                                        throw new Error("Invalid Response")
                                    }
                                }))
                            }
                        })
                        .catch((error) => { Promise.reject(error) })
                })
            } else {
                throw error
            }
        })
    }

    const retryApiPost = (url, data, header) => {
        // console.log('...res...', url, data, header)
        return API.post(url, { ...data, id: usersUid }, assocPath(['headers', 'uid'], firebaseuid, header)).then((res) => {
            if (res?.headers?.userid === String(usersUid)) {
                //console.log('...res...', res)
                return Promise.resolve(res)
            } else {
                throw new Error("Invalid Response")
            }
        }).catch(function (error) {
            if ([401, 500, 502, 504, 703, 704].includes(error?.response?.status)) {
                return new Promise(resolve => {
                    API.post("/userEndpoints/v1/renewIdToken", {
                        "token": refreshId
                    })
                        .then((res) => {
                            setTokenId(res.data.idToken)
                            if (!res.data.idToken) {
                                throw error
                            } else {
                                resolve(API.post(url, { ...data, id: usersUid }, {
                                    headers: {
                                        Authorization: res.data.idToken
                                    }
                                }).then((res) => {
                                    if (res?.headers?.userid === String(usersUid) && (res?.data?.id === Number(usersUid))) {
                                        return Promise.resolve(res)
                                    } else {
                                        throw new Error("Invalid Response")
                                    }
                                }))
                            }
                        })
                        .catch((error) => { Promise.reject(error) })
                })
            } else {
                Promise.reject(error)
            }
        })
    }
    const handleDrawerOpen = useCallback(() => {
        setOpen(!open);
    }, [open]);

    const handleDrawerClose = useCallback(() => {
        setOpen(!open);
    }, [open]);
    const handleChange = useCallback((newValue) => {
        setValue(newValue);
    }, []);

    const signOut = () => {
        sessionStorage.clear();
        window.location.reload();
        /*setUserId(null);
        setUserDetails(null);
        setApp(null);
        setUserPermission(null)
        //setUserAccessId(null)
        setTokenId(null)
        setRefreshId(null)
        setUserType(null)
        setFirebaseId(null)
        setUsersUid(null)
        resetDelayNotificationTime()
        resetAlertListNotificationTime()
        resetUnreadNotificationList()
        resetShowNotification()
        sessionStorage.clear();
        sessionStorage.clear();
        history.push("/login");*/
        



    };
    useEffect(() => {
        retryApiGet("/adminUserEndpoints/v1/getReportConfiguration", {

            headers: {
                'Authorization': tokenId,

            }
        }).then(({ data: { reportConfigurationList = [] } }) => {
            setLoading(false)
            const permissionFilteredReportConfigurationList = innerJoin(({id:reportsId},{id:permissionsId})=>reportsId===permissionsId,reportConfigurationList,permissionReports)
            setReportConfigList(permissionFilteredReportConfigurationList)
            const pageValue = permissionFilteredReportConfigurationList.map(({ name, active }) => ({
                name, accessible: active
            }))

            setPages(pageValue)
            permissionFilteredReportConfigurationList.some(({ name, active }) => {
                if (active) {
                    setValue(name)
                }
                return active
            })
        })
    }, [permissionReports])
    // console.log(pages, "pages")
    return (
        <HealthMonitorApiCall setUserId={setUserId} setLogoutUserToken={setLogoutUserToken} logoutUserToken={logoutUserToken} sessionFirebaseToken={sessionFirebaseToken} tokenId={tokenId} setTokenId={setTokenId} refreshId={refreshId}>
        <div style={{ display: "flex" }}>
            <ApplicationBar
                open={open}
                handleDrawerOpen={handleDrawerOpen}
                signOut={signOut}
                firebaseId={firebaseIdProp}
                app={'reportApp'}
            />
            {!loading&&<nav style={{ flexShrink: 0, whiteSpace: "nowrap" }} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <SideBar
                        open={open}
                        pages={pages}
                        value={value}
                        handleDrawerClose={handleDrawerClose}
                        handleChange={handleChange}
                        setOpen={setOpen}
                        signOut={signOut}
                        shouldShowDrawer={Boolean(value)}
                        reportsHandler
                    />
                </Hidden>
                <Hidden xsDown implementation="css">
                    <SideBar
                        open={open}
                        pages={pages}
                        value={value}
                        handleDrawerClose={handleDrawerClose}
                        handleChange={handleChange}
                        setOpen={setOpen}
                        signOut={signOut}
                        shouldShowDrawer={Boolean(value)}
                        reportsHandler
                    />
                </Hidden>
            </nav>}
            <div style={{position:'relative',marginTop:64,paddingTop:5}}>{loading && <div className={Loadclasses.Loading}><CustomLoader/> </div>}</div>

            <main style={{ flexGrow: 1, overflowX: "auto", width: "auto", }}>
                <div style={{ paddingTop: "95px" }}>
                    {reporConfigList.map(({ name, embededUrl: url, id }) => (
                        value===name && <div style={{
                            position: "relative",
                            width: "100%",
                            overflow: "hidden",
                            paddingTop: "56.25%"
                        }}>
                        
                            <iframe title="Reports" style={{
                                position: "absolute",
                                top: "0",
                                left: "0",
                                bottom: "0",
                                right: "0",
                                width: "100%",
                                height: "100%",
                                border: "none"
                            }}  key={id} src={url}></iframe>
                        </div>
                    ))}

                </div>
            </main>
        </div>
        </HealthMonitorApiCall>
    )
}
export default ReportApp;
